import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getUserToken } from './Common'

const EsignPrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return getUserToken() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/bgc/login', state: { from: props.location } }} />
                )
            }}
        />
    )
}

export default EsignPrivateRoute
