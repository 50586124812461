import { createContext, useContext, useState } from 'react'

const UserContext = createContext()

export const StepContext = ({ children }) => {
    const [step, setStep] = useState(0)

    return <UserContext.Provider value={{ step, setStep }}>{children}</UserContext.Provider>
}

export const useUser = () => {
    return useContext(UserContext)
}
