import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getUserToken } from './Common'

const EsignPublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return !getUserToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/bgc' }} />
            }}
        />
    )
}

export default EsignPublicRoute
