// These APIs are used in invincibleocean user dashboard and require token generated from emptra server
import axios from 'axios'
import { getUserToken } from '../Utils/Common'
import userAuthenticationToken from '../Login/RefreshAuthToken'
export const baseurl = process.env.REACT_APP_CONFIG_URL

const tokenapi = axios.create({
    baseURL: baseurl,
    headers: {
        token: getUserToken(),
        versionnumber: process.env.REACT_APP_VERSION,
    },
})

export default tokenapi

tokenapi.interceptors.response.use(
    function (response) {
        return response
    },
    async function (error) {
        if (error.response.status === 401) {
            // if(sessionStorage.getItem("initialization")){
            //     console.log('Already initiated');
            //     setTimeout(() => {
            //         const config= error.config;
            //         config.headers['token'] = localStorage.getItem("token");
            //         return tokenapi.request(config);
            //     }, 2000)
            // } else {
            //     console.log('New initialization');
            //     sessionStorage.setItem("initialization", true);
            //     try {
            //         await userAuthenticationToken();
            //         tokenapi.defaults.headers['token']=localStorage.getItem("token");
            //         const config= error.config;
            //         config.headers['token'] = localStorage.getItem("token");
            //         sessionStorage.removeItem("initialization");
            //         return tokenapi.request(config);
            //     } catch (refreshError) {
            //         console.error(refreshError);
            //         return
            //     }
            // }
            try {
                await userAuthenticationToken()
                tokenapi.defaults.headers['token'] = localStorage.getItem('token')
                const config = error.config
                config.headers['token'] = localStorage.getItem('token')
                return tokenapi.request(config)
            } catch (refreshError) {
                console.error(refreshError)
                return
            }
        }
        return Promise.reject(error)
    },
)

// Get details of user
export const userUrl = '/getTrulogProfile/'

// fetch API Credits
export const getCreditsUrl = '/truLog/getCredits/'

// fetch Product Credits
export const getProductCreditsUrl = '/truLog/getProductsCredits/'

// Get Client ID and Secret Key
export const getSecretKeyUrl = '/getSecret/key/'

// Create Client ID and Secret Key
export const createSecretKeyUrl = '/createSecret/key'

// Fetch Background Check Request List
export const getBGCRequestListsUrl = '/bgcClaimStatus/'

// Create Background Check Request
export const BGCRequestUrl = '/bgcClaim'

// Get BGC saved template
export const fetchTemplate = '/getTemplates/'

//Edit template
export const editTemplate = '/editBgcTemplate'

// Whitelist a ip
export const whiteLabelIp = '/admin/whiteLabelIp'

// Whitelist APi for useraccount
export const whiteLabelIpUser = '/user/whiteLabelIp'

// getWhiteLabelIp
export const getWhiteLabelIp = '/user/getWhiteLabelIp/'

// Whitelist APi for delete
export const deleteWhitelabeluser = '/user/removeIp/'

// Add new secondary user
export const addUser = '/user/createUsers/'

// Fetch list of all secondary users
export const subuserList = '/user/subUsersList'

// Update detail of secondary user
export const updateSubuser = '/user/updateSuperUsers'

// Delete secondary user
export const deleteSubUser = '/user/subUser/'

// Fetch secondary user details with userid
export const subUserDetails = '/user/subUserDetails/'

// fetch transactions for olx account(898245)
export const olxTransactions = '/vehicleLite2Transactions/?'

// Get default pricing and service keys
export const getDefaultPrices = '/user/getDefaultPrices'

// ******************** TruBGV starts ************************
// Create TruBGV request v1
export const createTruBgvRequest = '/truBgvRequestV1'

// Fetch TruBGV Details by request id
export const getTruBgvRequestIdV1 = '/getTruBgvRequestIdV1/'

// Change verification list in TruBGV (Select/Deselect)
export const selectVerifications = '/truBgv/editServicesV1'

// Aadhaar without otp
export const bgvAadhaarWithoutOtp = '/truBgv/aadharVerifyV1'

// AML
export const bgvAml = '/truBgv/amlVerificationV1/'

// Bank account verification
export const bgvBankAccountVerification = '/truBgv/bankAccountVerifyV1/'

// CIN Verification
export const cinVerificationV1 = '/truBgv/cinVerificationV1/'

// Court case v1
export const bgvCourtCaseV1 = '/truBgv/courtCaseV1Lts/'

// Court case v2 Post
export const bgvCourtCaseV2 = '/truBgv/courtCaseV2Lts/'

// Get Court Case V2
export const getCourtCaseV2 = '/truBgv/getCourtCaseV2Lts/'

// Court case v3 Post
export const bgvCourtCaseV3 = '/truBgv/courtCaseV3Lts/'

// Credit Score v1
export const creditScoreV1 = '/truBgv/creditScoreV1'

// Driving licence
export const bgvDrivingLicence = '/truBgv/drivingLicenseV1/'

// Electricity bill verification
export const bgvElectricityBillVerification = '/truBgv/electricityBillFetchV1/'

// Epfo UAN
export const epfoBasicSearchV1 = '/truBgv/epfoBasicSearchV1/'

//TruBgv EPFO Detail Search
export const epfoDetailsSearch = '/truBgv/epfoDetailsSearchV1/'

// Epfo Phone
export const epfophoneV1 = '/truBgv/epfoByMobileV1/'

// Epfo UAN
export const epfoUanV1 = '/truBgv/epfoByUanV1/'

// Face match
export const bgvFaceMatch = '/truBgv/faceMatchV1/'

// TruBgv Gst Basic Search
export const gstBasicSearch = '/truBgv/gstBasicSearchV1/'

// TruBgv Gst Details Search
export const gstDetailsSearch = '/truBgv/gstDetailsSearchV1/'

// PAN card
export const bgvPanCard = '/truBgv/panCardVerifyV1/'

// PanPlus Verification
export const panPlusV1 = '/truBgv/panPlusV1/'

// PAN verification aadhaar
export const bgvPanVerificationAadhaar = '/truBgv/panCardAadharSeedingV1/'

// Passport verification
export const bgvPassportVerification = '/truBgv/passportVerifyV1/'

// Upload pan
export const uploadPan = '/truBgv/panUpdoladV1/'

// Upload Aadhaar
export const uploadAadhaar = '/truBgv/aadhaaruploadV1/'

// Upload ITR
export const uploadITR = '/truBgv/itruploadV1/'

// Upload ITR Verification
export const uploadITRVerification = '/truBgv/form26ExtractionV1Lts'

// Upload Driving license
export const uploadDL = '/truBgv/dlUploadV1/'

// Upload other document
export const uploadOtherDoc = '/truBgv/otherDocUploadV1/'

// Vehicle verification
export const bgvVehicleVerificationV1 = '/truBgv/vehicleVerificationV1/'

// Voter id verification
export const bgvVoterIdVerification = '/truBgv/voterIdV1/'

// Fetch price for all veridications in TruBGV
export const getBgvPricesUser = '/getBgvPricesUser/'

// Phone KYC without OTP
export const phoneKycWithoutOTP = '/truBgv/phoneKyc/withoutOtpV1'

// Aadhaar Verification with OTP (Send OTP)
export const sendAadhaarWithOTP = '/truBgv/requestAadhaarOtpV1'

// Aadhaar Verification with OTP (Verify OTP)
export const verifyAadhaarWithOTP = '/truBgv/submitAadhaarOtpV1'

// Vehicle Challan Verification
export const vehicleChallanVerify = '/truBgv/vehicleChallanVerifyV1'

// Fast Tag Check
export const fastTagCheck = '/truBgv/fasTagCheckV1'

// IFSC Code Verification
export const IFSCCodeVerify = '/truBgv/IFSCCodeVerifyV1'

// DIN Check
export const DINCheckV1 = '/truBgv/DINCheckV1'

// PAN Name Matcher
export const panNameMatch = '/truBgv/panNameMatchV1'

// Aadhaar to Masked PAN
export const aadhaarToMaskPan = '/truBgv/aadhaarToMaskPanV1'

//Mobile otp verification Send otp\
export const sendOtp = '/truBgv/sendOtpV1'

//Mobile otp verification Verify otp\
export const verifyOtp = '/truBgv/verifyOtpV1'

// Close request after this user can not add new verifications
export const closeRequest = '/truBgv/closeRequestV1'

//Phone kyc with otp (send otp)
export const generatePhoneKycOtp = '/truBgv/generateOtp'

//Phone kyc with otp (send otp)
export const verifyPhoneKycOtp = '/truBgv/phoneKyc/SubmitOtpV1'

//Email send otp
export const generateEmailOtp = '/truBgv/emailOtpV1'

//Email verify otp
export const verifyEmailOtp = '/truBgv/emailVerifyOtpV1'

//TruBgv reset service
export const resetService = '/truBgv/resetVerificationV1'

// ******************** TruBGV ends ************************

// Get api list with price
export const fetchApiPricing = '/fetchApiPricing'

// Get Production Transaction data
export const getProductionTransaction = '/user/getProductsTransaction/?'

//Add individual gst
export const addGst = '/individualGstinVerify'

// Add GST details of user
export const addGstDetails = '/add-gst-details'

// Get GST details of user
export const getGstDetails = '/get-gst-details'

// Delete GST details of user
export const deleteGstDetails = '/delete-gst-details/'

//I dont have Gst for individual
export const iDontHaveGSTIndividial = '/IdontHaveGstInV1'

// Get KYC status (Individual KYC)
export const fetchKYCStatus = '/user/getKyc/userInfo/'

// Get company KYC info
export const getCompanyInfo = '/user/getKyc/companyInfo/'

// Export csv of a single user
export const exportUserTransactions = '/user/export/transactions/?'

// Get address name and address of default QR code
export const trulogAddressUrl = '/user/truLog/getAddress/'

// Get Category Price
export const categoryPrice = '/getBgcPrices/'

// Esign Adhaar Sign Request V1
export const esignAadhaarRequestV1 = '/eSignOcean/aadharSignRequestV1'

// E sign Request API
export const sendEsignRequest = '/eSign/request'

//Esign Dynamic Price
export const esignPrice = '/eSignOcean/aadharSignDynamicPrices'

// API Logs User Side in API Usage
export const apiLogs = '/user/apiLogs/'

//Api wallet transaction
export const apiWalletTransaction = 'user/getPaymentDetailsApis'

//Product wallet transaction
export const productWalletTransaction = '/user/getPaymentDetailsProducts'

// Invoice Datails Details ( My Account )
export const invoiceDetailData = '/user/getInvoiceList/'

// Add new normal case verification
export const normalCaseVerification = '/emptra/courtCaseV1'

// Add new immediate case verification
export const immediateCaseVerification = '/emptra/courtCaseV2'

export const delayCaseVerification = '/emptra/courtCaseV3'

// Enable-Disable Reminder Update
export const updateReminder = '/user/remainderUpdate'

// Post Email CC
export const addCCEmail = '/user/remainder'

// get CC Emails
export const getRemainderStatus = '/user/getReminderStatus/'

// Get Remainder Email
export const remainderEmailUpdate = '/user/getRemainderEmails/'

//Bulk Hit
export const bulkHitCsv = '/setAutoHitCsv'

//Bult Hit all running
export const getAllAutoHit = '/getAllAutoHitStatus'

//Bulk  hit api list
export const getAllBulkHitApi = '/getAllAutoHitApis'

//Bulk hit stop call
export const stopBulkHitCall = '/clearAllAutoHits'

//Bulk hit fetch data
export const startFetchingBulkHits = '/getAllAutohitApiData'

//BUlk hit individual status
export const getAllHitLoad = '/getAutoHitStatus'

// Add Phone No in API keys
export const requestOtp = '/user/requestOtp'

// Verify Phone No in API keys
export const otpVerify = '/user/otpVerify'

// Save Phone Number If no are from other than India(+91)
export const phoneSaveVerification = '/user/phoneSaveVerification'

// User Reset password
export const resetUserPassword = '/user/updatePassword'

// Add bulk PAN extract request
export const addBulkPAN = '/bulkPanFetch'

// Get bulk pan verification detail by request id
export const getBulkPanDetail = '/bulkPanByRequestId/'

// Get bulk pan verifications list by used id
export const getBulkPanList = '/bulkPanByUserId/'

// Add bulk PAN extract with masked aadhaar request
export const addBulkPANv2 = '/bulkAadharWithMaskV2'

// Get cost of bulk pan version cost
export const getBulkPanCost = '/userPrice'

// Send OTP on Change phone number in Profile Details(My account)
export const sendPhoneOtp = '/user/requestOtp'

// Verify OTP on Change phone number in Profile Details(My account)
export const verificationOTP = '/user/otpVerify'

// fetch transaction count and credits added in account
export const accountTypeCount = '/user/accountTypeCount/?'

// transaction for last 7 days
export const latestTransaction = '/user/userTransactionApi/?amountType=Debit'

// product transaction for last 7 days
export const userTransactionProducts = '/user/userTransactionProducts'

// fetch product amount of debit, credit and refund from product wallet
export const accountTypeTotal = '/user/accountTypeTotal/?amountType='

// fetch product usage by percentage
export const getProductsUsagePercentage = '/user/getProductsUsagePercentage'

// fetch digital Sign Count
export const digitalSignCount = '/user/digitalSignCount'

// Change Email send OTP
export const requestOtpResetEmail = '/user/requestOtpResetEmail'

// Change Email Verify OTP
export const userResetEmailOtpVerification = '/user/userResetEmailOtpVerification'

// Esign data with date
export const esignDateFilter = '/user/digitalSignCountStatus'

//Export api logs user
export const exportApiLogs = '/user/exportapilogs/'

// Fetch list of all OCR APIs Sandbox
export const getOcrApisSandbox = '/user/getOcrApisSandbox'

// Fetch single OCR API detail
export const getSingleOcrApiDetails = '/user/getOcrApisSandboxDetails/'

// Send Aadhaar OTP in Facematch
export const sendFaceMatchAadhaarRequest = '/truBgv/faceMatchWithAadhaarRequest'

// get Aadhaar OTP in faceMatch
export const submitAadhaarRequest = '/truBgv/faceMatchWithAadhaarSubmit'

// Face Match Comparison in BGV
export const compareFacematch = '/truBgv/faceMatchWithAadhaar'

// Reset Face Match Comparison in BGV
export const resetFaceMatch = '/truBgv/reset/faceMatchWithAadhaar'

// This API creates a new flow for a user in onboarding solution section.
export const createOnboardingSolutionFlow = '/createOnboardingSolutionFlow'

// This API fetch all the saved flows by a user in onboarding solution section
export const getOnboardingSolutionFlow = '/getOnboardingSolutionFlow/'

// This API will allow user to edit the previously saved flow in onboarding solution section
export const editOnboardingSolutionFlow = '/editOnboardingSolutionFlow'

// This API fetch list of verifications of all flows for one user.
export const getVerificationList = '/getVerificationList/'

// This API creates a new verificationId for each flowId on the basis of phone number and gives verificationId in response. This will be used by the user who created the flow.
export const addOnboardingSolutionUser = '/addOnboardingSolutionUser'

// fetch product status
export const fetchProductStatus = '/fetchProductControl/'

// Post SMTP details on onboarding solutions.
export const editSMTP = '/onboardingSolution/smtp'

// fetch product status
export const fetchSMTP = '/onboardingSolution/smtp/'

// Onboarding Theme Data Post
export const onboardingThemeData = '/onboardingSolution/theme'

// Onboarding Theme Create subdomain
export const createSubdomain = '/user/createsubdomain'

// Onboarding Theme Fetch subdomain
export const getSubdomain = '/user/getSubdomainList/'

// Onboarding Delete SubDomain
export const deleteSubdomain = '/user/deleteSubdomain'

// Onboarding fetch price
export const fetchOnboardingPrice = '/getOnboardingPricesUser/'

// Filter function
export const dataFilterFunction = '/search/verificationFilter/?'

// Global OCR API Upload Documents
export const globalOcrUploadDocuments = '/ocr/globalOcrSuite/uploadDocuments'

// Global OCR GET Detailed Information
export const globalOcrGetDetails = '/getOcrDetails/'

// Global OCR Edit Details
export const globalOcrEditDetails = '/ocr/edit/globalOcrSuite/uploadDocuments'

// Global OCR Edit Details
export const globalOcrFaceMatch = '/ocr/globalOcrSuite/getOcrData'

// Global OCR Edit Details
export const globalOcrDataUser = '/getOcrData/'

// video KYC Add Agent
export const videoKycAddAgent = '/user/createAgents'

// video KYC Fetch Agent
export const videoKycFetchAgent = '/user/getAgentList'

// video KYC Fetch Templates
export const videoKycFetchTemplates = '/user/getTemplates'

// video KYC Fetch Templates
export const videoKycCreateTemplates = '/user/createTemplates'

// Video KYC Update Agent Details by Agent Id
export const videoKycUpdateAgentDetails = '/user/update/AgentsDetails'

//Request bulkHit create request
export const requestBulkHitCreate = '/bulkhit/createRequest'

//Request bulkHit fetch created requests
export const fetchRequests = '/bulkhit/fetchRequests/'

//Video KYC Fetch the Analytics Data
export const videoKycAnalyticsData = '/user/totalRequestCount'

// Video KYC Get User Request List
export const videoKycGetUserRequestList = '/user/getUserRequestList'

// Video KYC Calls Count
export const videoKycCallsCount = '/user/callsCount/'

// API Tracking - List of all the APIs
export const apiTracking = '/user/apiCachingData/'

// API Tracking - Export API Tracking logs
export const exportApiTracking = '/export/apiCachingData/'

// Fetch Tracking Servicess
export const fetchTrackingServices = '/user/getTracking/services'

// Filter the API Tracking Data
export const filterTracking = '/userFilter/tracking/apiCachingData'

//Fetch the Report Details
export const fetchReportDetails = '/user/getRequestDetails/'

// Current User Details Price
export const currentUserPrice = '/user/priceList/'

// Fetch the Filter Agent VideoKYC Data
export const fetchFilterAgentData = '/search/vkyc-agent-filter/'

// Fetch the Filter Report VideoKYC Data
export const fetchFilterReportData = '/search/vkyc-reports-filter/'

// Upload documents for international KYC
export const kycDocOcr = '/kyc/docs-ocr'

// Face match for international KYC
export const kycFaceMatch = '/kyc/face-match'

// Delete API for onboarding templates & Flow
export const deleteFlow = "/deleteOnboardingSolutionFlow/";
