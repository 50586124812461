import jwt_decode from 'jwt-decode'

export const userToken = () => {
    const token = localStorage.getItem('token')
    if (token) return token
    else return null
}

export const getUserId = () => {
    const token = localStorage.getItem('token')
    var userId = token ? jwt_decode(token).id : null
    return userId
}

export const getSubUserId = () => {
    const token = localStorage.getItem('token')
    var subUserId = token ? jwt_decode(token).subUserId : null
    return subUserId
}

export const getUserToken = () => {
    return localStorage.getItem('token') || null
}

export const setSession = (token) => {
    localStorage.setItem('token', token)
}

export const setRefreshSession = (token) => {
    localStorage.setItem('refresh-token', token)
}

export const removeSession = () => {
    localStorage.removeItem('token')
}

export const setAdminToken = (token) => {
    return localStorage.setItem('admin-auth', token)
}

export const setAdminRefreshToken = (token) => {
    localStorage.setItem('admin-refresh-token', token)
}

export const getAdminToken = () => {
    return localStorage.getItem('admin-auth') || null
}

export const removeAdminToken = () => {
    return localStorage.removeItem('admin-auth')
}

export const setBgcToken = (token) => {
    return sessionStorage.setItem('bt', token)
}

export const getBgcToken = () => {
    return sessionStorage.getItem('bt') || null
}
